.preAssementTab {
    padding: 0 1rem;
    padding-bottom: 1rem;

    .textSectionTop {
        align-items: center;
        display: flex;

        padding-top: 2rem;
    }

    .infotext {
        color: #08182e;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .Innerinnputsection {
        align-items: start;
        display: flex;
        gap: 2%;
        margin-top: 1rem;
    }

    .subdivision {
        width: 32%;
    }
}