//custom checkbox start...

.custom-checkbox-Parent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 1.5rem;
}

textarea {
  &::placeholder {
    opacity: 1;
    font-size: 14px;
    color: #AFAFAF !important;
    font-weight: 400;
  }
}

.flatpickr-input {
  &::placeholder {
    opacity: 1;
    font-size: 14px;
    color: #AFAFAF !important;
    font-weight: 400;
  }
}

input {

  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
  }

}

.custom-checkbox-title {
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
}

.custom-checkbox {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(72%, -50%);
  cursor: pointer;
  display: inline-block;
  width: 24px;
  height: 24px;
}

.custom-checkbox-straight {
  position: relative;
  top: unset;
  left: unset;
  transform: unset;
}

.custom-checkbox input {
  opacity: 0;
  width: 0;
  height: 0;
}

.checkmark {
  position: absolute;
  top: 1px;
  left: 1px;
  height: 22px;
  /* Adjusted height to accommodate the tick mark */
  width: 22px;
  /* Adjusted width to accommodate the tick mark */
  background: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkedBox {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: var(--main-color);
}

.checkmark.checked {
  /* No longer need to set background color or border here */
  border: 1px solid #e5e5e5;
}

.checkmark svg {
  width: 16px;
  height: 16px;
  color: var(--main-color);
}

/* Add additional styles for the green tick mark */
.checkmark.checked svg {
  display: block;
  /* Replace 'green_tick.png' with the actual path to your green tick mark image */
}

//custom checkbox end...

.copy_icon {
  fill: #5f6163;
  transition: all linear 0.2s;

  &:hover {
    font-weight: 550;
    fill: var(--main-color);
  }
}


input[type="time"] {
  /* Your custom styles */
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* Hide the default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::placeholder {
    font-size: 30px;
  }
}


input[type=time]::-webkit-calendar-picker-indicator {
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

/* Style the custom dropdown arrow */
input[type="time"]::before {
  // content: 'HH:MM';
  // color: #5a5656;
  position: absolute;
  // background: #fff;
  width: 200px;
}

input[type="time"]:focus:before {
  width: 0;
  content: '';
}

/* Style the dropdown list */
input[type="time"]::-webkit-calendar-picker-indicator {
  /* Hide the default dropdown arrow for WebKit browsers */
  //  display: none;
}