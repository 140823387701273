.PatientInformation {
    padding: 0 1rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
    height: calc(100% - 15rem);
    overflow: scroll;

    .labelsavelist {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin-top: 20px;
    }

    .clearlabel {
        align-items: center;
        border: 1px solid #d1d7e1;
        border-radius: 6px;
        color: #272727;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .savelabel {
        align-items: center;
        background: #37b12b;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .textSectionTop {
        align-items: center;
        display: flex;

        padding-top: 2rem;
    }

    .topName-section {
        align-items: center;
        display: flex;
        margin-bottom: 1.2rem;
    }

    .name {
        color: #08182e;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .rightside {
        width: 100%;
    }

    .infotext {
        color: #08182e;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .Innerinnputsection {
        align-items: start;
        display: flex;
        gap: 2%;
        margin-top: 1rem;
    }

    .subdivision {
        width: 32%;
    }
}