.summaryhistory {
    padding: 0 1rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
    height: calc(100% - 15rem);
    overflow: scroll;

    .notes {

        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #202020;

    }

    .notestect {

        font-size: 13px;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #202020;
    }

    .mediciantitle {

        font-size: 16px;
        margin-bottom: 1rem;
        font-weight: 500;
        line-height: 28px;
        text-align: left;
        color: #19376D;
        display: flex;
        align-items: center;
        width: max-content;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }

        svg {
            margin-left: 6px;
        }

    }

    .leftsidetable {

        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        width: 25rem;
        min-width: 25rem;
        height: 100%;
        overflow: scroll;
        padding-right: 2rem;
    }

    .tablesierigt {
        width: 100%;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;
        padding-left: 1rem;
        border-left: 1px solid #737373;
        height: 100%;
    }

    .bottomtablesummary {
        display: flex;
        align-items: self-start;
        height: 100%;
    }

    .tablistitem {
        border-top: 1px solid #737373;
        border-bottom: 1px solid #737373;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1.3rem;
    }

    .f-box {
        display: flex;
        align-items: center;
        gap: 1.2rem;
    }

    ul {
        margin-left: 17px;
    }

    li {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
        color: #737373;
        margin-bottom: 9px;

        span {
            font-weight: 600;
            color: #202020;
        }
    }

    .titlebox {

        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #737373;

        span {
            font-weight: 600;
            color: #202020;
        }
    }

    .top {
        display: flex;
        align-items: start;
        justify-content: space-between;
    }

    .left {

        width: 495px;


    }

    .des {

        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        color: #737373;

    }

    .NameRed {

        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #FC4F00;

    }









    .labelsavelist {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin-top: 20px;
    }

    .clearlabel {
        align-items: center;
        border: 1px solid #d1d7e1;
        border-radius: 6px;
        color: #272727;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .savelabel {
        align-items: center;
        background: #37b12b;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .textSectionTop {
        align-items: center;
        display: flex;

        padding-top: 2rem;
    }

    .topName-section {
        align-items: center;
        display: flex;
        margin-bottom: 1.2rem;
    }

    .name {
        color: #08182e;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .rightside {
        width: 100%;
    }

    .infotext {
        color: #08182e;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .Innerinnputsection {
        align-items: start;
        display: flex;
        gap: 2%;
        margin-top: 1rem;
    }

    .subdivision {
        width: 32%;
    }

    .prestingSection {
        padding: 1rem;
        background: #FFF7F3;
        display: flex;
        gap: 1%;

        .innertite {
            margin-bottom: 6px;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: left;
            color: #121212;

        }

        .labeltext {
            background: #FFFFFF;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
            color: #121212;
            width: 100%;
            display: flex;
            justify-content: space-between;
            height: 34px;
            display: flex;
            align-items: center;
            border-radius: 6px;
            display: flex;
            padding: 0 1rem;
        }

        .textlabwl {

            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: right;
            color: #A33300;
            width: max-content;
            height: 18px;
            padding: 0px 12px;
            height: 20px;
            display: flex;
            border-radius: 6px;
            align-items: center;
            background: #FFF7F3;

        }
    }

    .title {
        //styleName: xl/Semibold;
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #08182E;

    }

    .react-toggle--checked .react-toggle-track {
        background-color: #FC4F00 !important;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        color: white;

    }

    .react-toggle-track-x {
        position: absolute;
        width: 10px;
        height: 10px;
        top: 10px;
        bottom: 0px;
        margin-top: auto;
        margin-bottom: auto;
        line-height: 0;
        right: 20px;
        opacity: 1;
        transition: opacity 0.25s ease;
        font-weight: 600;
        font-size: 12px;
    }

    .react-toggle-track {
        width: 50px;
        height: 24px;
        padding: 0;
        border-radius: 30px;
        background-color: #BAC3D3 !important;

        &:hover {
            background-color: #BAC3D3 !important;
        }
    }

    .react-toggle-track-check {
        position: absolute;
        width: 14px;
        height: 10px;
        top: 9px;
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        box-shadow: 0px 0px 0px 0px #fff !important;
    }

    .react-toggle--focus .react-toggle-thumb {
        box-shadow: 0px 0px 0px 0px #fff !important;
    }

    .react-toggle--checked .react-toggle-thumb {
        left: 31px;
        border-color: transparent;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
        }
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        background: white;
    }

    th,
    td {
        border: 1px solid lightgray;
        padding: 8px;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;

        border: 0px;
        max-width: 400px;

        div {
            height: 100%;
        }

        &:first-child {}

        &:last-child {
            text-align: left;
            //  width: 150px;
        }
    }

    td {
        padding: 16px 15px;
        border-bottom: 1px solid #E3E7ED;
        font-size: 14px;
        color: #08182E;
        white-space: normal;
    }

    th {
        background: #345E7D;
        text-overflow: ellipsis;
        color: #fff;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;

        font-weight: 500;
    }

    table tr:first-child td {
        border-top: none;
    }

    .containerpage {

        width: 100%;

        height: 300px;
        margin-bottom: 1rem;
    }

    .tWrap {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 6px;

        border: 1px solid #E3E7ED;

        &__head {
            flex: 0 0 37px; // for Safari and IE
            overflow-y: scroll;
        }

        &__body {
            overflow-y: scroll;
        }
    }
}