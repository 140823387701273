.summarypage {

    padding: 1rem;
    height: calc(100% - 14rem);
    overflow: scroll;

    .maintabelist {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
        gap: 3%;
    }

    .tablist {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #E3E7ED;
        box-shadow: 0px 1px 6px 0px #1018280A;
        border-radius: 6px;
        width: 31.333%;
        margin-bottom: 1.3rem;

        .digit {

            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #767A85;

        }

        .name {

            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: -0.02em;
            text-align: left;
            color: #1F2026;

        }
    }

    .title {
        //styleName: xl/Semibold;
        margin-bottom: 1rem;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #08182E;

    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        background: white;
    }

    th,
    td {
        border: 1px solid lightgray;
        padding: 8px;
        text-align: left;
     
        width: 100%;
        border: 0px;
        max-width: 500px;

        &:first-child {}

        &:last-child {
            text-align: right;
            width: 50px;
        }
    }

    td {
        padding: 16px 15px;
        border-bottom: 1px solid #E3E7ED;
        font-size: 14px;
        color: #08182E;
    }

    th {
        background: #EFF0F6;
        text-overflow: ellipsis;
        color: #8C9BB5;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;

        font-weight: 500;
    }

    table tr:first-child td {
        border-top: none;
    }

    .containerpage {

        width: 100%;

        height: 300px;
        margin-bottom: 1rem;
    }

    .tWrap {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 6px;

        border: 1px solid #E3E7ED;

        &__head {
            flex: 0 0 37px; // for Safari and IE
            overflow-y: scroll;
        }

        &__body {
            overflow-y: scroll;
        }
    }
}