.cliincpage {
    padding: 0 1rem;
    padding-bottom: 1rem;
    padding-top: 2rem;
    height: calc(100% - 15rem);
    overflow: scroll;

    .queTitle {
        font-weight: 500;
        color: rgb(122, 122, 122);
        margin-top: 0px;
        font-size: 12px;
        margin-bottom: 8px;
    }

    .saveSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;

        div {
            border: 1px solid #FC4F00;
            width: 158px;
            height: 36px;
            border-radius: 5;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 1rem;
            background: #FC4F00;
            color: #fff;
            border-radius: 5px;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .questionsection {

        position: relative;
    }

    .dropdonwlistdata {
        border: 1px solid #E9EBF3;
        border-radius: 10px;
        border-radius: 10px;
        width: 100%;
        padding: 1.5rem;
        position: absolute;
        background: white;
        z-index: 1;
        top: 4.5rem;
        padding-top: 10px;

        div {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            cursor: pointer;
            margin-top: 1rem;

            &:hover {
                transform: scale(1.03);
            }
        }
    }

    .queTitledrop {
        max-width: 100%;
        padding: 0.6rem 1.5rem 0.6rem 10px;
        color: rgb(15, 6, 9);
        font-size: 14px;
        margin: 0px;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        /* font-size: 0.8rem; */
        background: white;
        width: 100%;
        /* max-width: 500px; */
        /* max-height: 400px; */
        height: 37px;

        outline: none;
        transition: all ease-in 0.2s;
        position: relative;

        svg {
            position: absolute;
            right: 10px;

            top: 14px;
        }
    }

    .queAnswerinput {
        max-width: 100%;
        padding: 0.6rem 1.5rem 0.6rem 10px;
        color: rgb(15, 6, 9);
        font-size: 14px;
        margin: 0px;
        border-radius: 5px;
        border: 1px solid #e5e5e5;
        /* font-size: 0.8rem; */
        background: white;
        width: 100%;
        /* max-width: 500px; */
        /* max-height: 400px; */
        overflow-y: scroll;
        outline: none;
        transition: all ease-in 0.2s;
    }

    .labelsavelist {
        align-items: center;
        display: flex;
        gap: 1rem;
        margin-top: 20px;
    }

    .clearlabel {
        align-items: center;
        border: 1px solid #d1d7e1;
        border-radius: 6px;
        color: #272727;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .savelabel {
        align-items: center;
        background: #37b12b;
        border-radius: 6px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        justify-content: center;
        letter-spacing: -.02em;
        line-height: 20px;
        text-align: left;
        width: 102px;

        &:hover {
            transform: scale(1.03);
            cursor: pointer;
        }
    }

    .textSectionTop {
        align-items: center;
        display: flex;

        padding-top: 2rem;
    }

    .topName-section {
        align-items: center;
        display: flex;
        margin-bottom: 1.2rem;
    }

    .name {
        color: #08182e;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .rightside {
        width: 100%;
    }

    .infotext {
        color: #08182e;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -.01em;
        line-height: 26px;
        text-align: left;
    }

    .Innerinnputsection {
        align-items: start;
        display: flex;
        gap: 2%;
        margin-top: 1rem;
    }

    .subdivision {
        width: 32%;
    }
}