.daycalendar {
    width: 100%;
    height: calc(100% - 147px);
    margin-bottom: 1rem;
    padding-top: 2rem;

    .divspacing {
        display: flex;
        align-items: self-start;
        gap: 2rem
    }

    .crirlcebutton {
        width: 56px;

        height: 32px;
        max-width: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .round {
        width: 10px;
        height: 10px;
        border-radius: 6px;
        background: #BAC3D3;
        min-width: 10px;
    }

    .roundmain {
        display: flex;
        align-items: self-start;
        margin-top: 10px;
    }

    .openclanderDrawer {

        width: 294px;
        height: 341px;
        z-index: 99;
        box-shadow: 0px 1px 15px 0px #142E6E40;
        background: white;
        position: absolute;
        top: 32px;
        padding: 1rem 0rem;
        border-radius: 10px;

        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 17px;
            top: -11px;
            border-bottom: 15px solid #fff;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
        }

    }

    .sessionstart {
        height: 32px;
        width: max-content;
        border-radius: 6px;

        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        padding: 0 1rm;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        color: white;
        text-align: left;
        background: #37B12B;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 1rem;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    .bottomdown {
        display: flex;
        align-items: self-start;
        gap: 1rem;


    }



    .line {
        height: 10px;
        width: 100%;
        background: #F1F5F8;

    }

    .mainmiddle {
        display: flex;
        align-items: self-start;
        padding: 1rem;

        svg {
            margin-right: 10px;
        }
    }

    .topmidel {
        width: 100%;



        .cricle {
            width: 10px;
            height: 10px;
            background: #67DAFF;
            margin-right: 3px;
            border-radius: 6rem;

        }

        .middleshort {
            color: #1F2026;

            font-size: 14px;
            font-weight: 500;
            line-height: 16.94px;
            text-align: left;

        }

        .middeltext {

            font-size: 12px;
            font-weight: 400;
            line-height: 14.52px;
            text-align: left;
            margin-bottom: 2px;
            display: flex;
            align-items: center;
        }
    }

    .topsel {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        padding: 0rem 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid #D1D7E1
    }

    .img-text-section {
        display: flex;
        align-items: self-start;

        img {
            margin-right: 10px
        }
    }

    .subtext {

        font-size: 12px;
        font-weight: 400;
        line-height: 18.94px;
        text-align: left;

        color: #414D55;

    }

    .titleName {

        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: #1F2026;

    }


    .maindv {
        height: 100%;
        padding-top: 0px !important;
    }

    .time {

        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: -0.02em;
        text-align: left;
        color: #767A8580;

    }

    .linebreak {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 75%;
    }

    .celdndartite {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #1F2026;
        height: 32px;
        border-radius: 6px;
        padding: 0 0.5rem;
        border: 0.5px solid #06C1FD;
        border-left: 3px solid #06C1FD;
        margin-top: 10px;
        cursor: pointer;
        position: relative;

        // &:hover {
        //     transform: scale(1.02);
        // }
    }

    .dayNametext {
        top: 9px;
        right: 9px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: right;
        color: #08182E;
        position: absolute;
    }

    .tWrap {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-radius: 6px;

        border: 1px solid #E3E7ED;

        &__head {
            flex: 0 0 37px; // for Safari and IE
            overflow-y: scroll;
        }

        &__body {
            overflow-y: scroll;
        }
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
        background: white;
    }

    th,
    td {
        border: 1px solid lightgray;
        padding: 8px;
        text-align: left;

        white-space: nowrap;

        border: 0px;
        max-width: 400px;

        &:first-child {
            width: 100px;
        }

        &:last-child {}
    }

    td {
        padding: 9px 9px;
        border: 1px solid #E3E7ED;
        font-size: 14px;
        color: #08182E;
        height: 50px;
        position: relative;
        cursor: pointer;
        text-align: left;
    }

    th {
        background: #F5F7FA;
        text-overflow: ellipsis;
        color: #08182E;
        padding: 8px 15px;
        font-size: 14px;
        font-weight: 400;
        line-height: 19.36px;
        text-align: left;
        cursor: pointer;
        font-weight: 500;
    }

    table tr:first-child td {
        border-top: none;

    }

    .cutsommodel {
        width: 11rem;
    }



}

@media only screen and (max-width: 1400px) {
    .daycalendar .divspacing {

        gap: 1rem;
    }

    .daycalendar .cutsommodel {
        width: 5rem;
    }

    .daycalendar .topmidel .middleshort {
        color: #1F2026;
        font-size: 12px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
    }

    .daycalendar .sessionstart {
        height: 32px;
        // width: 7rem;

    }

    .daycalendar .crirlcebutton {
        width: 32px;
        height: 32px;
    }
}