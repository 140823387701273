.createbilling {
    height: calc(100vh - 171px);
    overflow: scroll;

    .dropdownlist {
        width: 100%;
        height: 10rem;
        background: #F5F7F9;
        border: 1px solid #D1D7E1;
        border-radius: 6px;
        position: absolute;
        overflow: scroll;
        top: 38px;
        padding: 0.6rem 1.5rem 0.6rem 10px;
        z-index: 9;
        left: 0;
    }

    input[type="date"].selected-date {
        background-color: #FFA500 !important;
        /* Orange color for selected date */
    }

    .leftsidetable {
        width: 50%;
        white-space: pre-line;

    }

    input[type="date"] {
        background-color: #FC4F00;
        /* Change this color to whatever you prefer */
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 4px;
    }

    input[type="date"]:focus {
        background-color: #FC4F00;
        /* Change the background color when the input is focused */
        border-color: #FC4F00;
        outline: none;
    }

    .tableDropdownItems {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .SubNameTable {
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #757575;

    }

    .NameTable {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #1F2026;
    }

    .showthedropdown {
        width: 100%;
        height: 10rem;
        background: white;
        border: 1px solid #D1D7E1;
        border-radius: 6px;
        position: absolute;
        overflow: scroll;
        top: 4.2rem;
        padding: 0.6rem 1.5rem 0.6rem 10px;
        z-index: 9;
    }

    .listitemNamedrop {
        display: flex;
        align-items: center;
        color: #7587A7;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        height: 30px;
        display: flex;
        align-items: center;
        cursor: pointer;
        padding-left: 1rem;
        padding-right: 1rem;

        &:hover {
            background: #fc4f00;
            color: #fff;
            transform: scale(1.02);
            font-style: 600;
            border-radius: 6px;
        }
    }

    .invocie-Name {

        font-size: 36px;
        font-weight: 700;
        line-height: 43.57px;
        text-align: left;
        color: #1F2026;


    }

    .Innerinnputsection {
        align-items: start;
        display: flex;
        gap: 2%;
        margin-top: 1rem;
    }

    .finaltext {
        font-size: 14px;
        margin: 0rem;
        border: 1px solid #D7D7D7;
        border-radius: 6px;
        height: 34px;
        color: #757575;
        background: #F5F7F9;
        text-align: right;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: end;
        padding: 0 0.5rem;
    }

    .dateinput {
        max-width: 100%;
        width: 100%;
        padding: 0 0.5rem;
        color: rgb(15, 6, 9);
        font-size: 14px;
        margin: 0rem;
        border: 1px solid #D7D7D7;
        border-radius: 6px;
        height: 34px;
        color: #757575;
        background: #F5F7F9;
    }

    th:nth-child(2),
    td:nth-child(2) {
        width: 184px;
    }

    th:nth-child(3),
    td:nth-child(3) {
        width: 170px;
    }

    th:nth-child(4),
    td:nth-child(4) {
        width: 120px;
    }

    th:nth-child(5),
    td:nth-child(5) {
        width: 80px;
    }

    th:nth-child(6),
    td:nth-child(6) {
        width: 120px;
    }

    .addsvg {
        width: 34px;
        height: 34px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }
    }

    th:last-child,
    td:last-child {
        width: 110px !important;
    }

    .priceinput {
        width: 100%;
        height: 34px;

        background: #F5F7F9;
        border: 1px solid #D7D7D7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5rem;
        border-radius: 6px;

        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #757575;
    }

    .dropdown-createbill-main {
        position: relative;
        width: 100%;
    }

    .dropdown-createbill {
        width: 100%;
        height: 34px;

        background: #F5F7F9;
        border: 1px solid #D7D7D7;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0.5rem;
        border-radius: 6px;

        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #757575;

        svg {
            height: 12px;
            width: 12px;
        }

    }

    th:first-child,
    td:first-child {
        width: 270px !important;
    }

    .topdec-cleintcreate {
        display: flex;
        align-items: self-start;
        justify-content: space-between;
    }

    .serchbuttom {
        background: #FC4F00;
        height: 35px;
        width: max-content;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        border-radius: 6px;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        margin-top: 24px;
        color: #fff;

        svg {
            margin-left: 10px;
        }
    }

    .searchByName {
        //styleName: xl/Semibold;
        margin-top: 41px;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #08182E;
        margin-bottom: 10px;

    }

    .deatiltextaddress {
        color: #757575;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-align: left;
    }

    .addressdecrbtion {

        font-size: 16px;
        font-weight: 600;
        line-height: 16px;
        text-align: left;
        color: #19213D;
        margin-bottom: 10px;
    }

    .invocie-id {

        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        letter-spacing: -0.02em;
        text-align: left;
        background: #F5F7FA;
        height: 27px;
        width: max-content;
        padding: 0 0.5rem;
        display: flex;
        align-items: center;
        border: 6px;

        span: {
            font-weight: 600;
        }



    }
}