.loginPage {
  width: 100vw;
  height: 100vh;
  background: #fbfbfb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #0B2447 0%, #0D264C 31.1%, #122E5A 67.68%, #19376D 100%);

  &>.topNav {
    position: fixed;
    top: 0;
    left: 0;
    height: 3rem;
    display: flex;
    align-items: center;
    width: max-content;
    z-index: 1;

    &>img {
      height: 2.2rem;
      width: 2.2rem;
      padding: 10px;
      cursor: pointer;
    }

    &>.menuDropdown {
      position: absolute;
      top: 120%;
      left: 1rem;
      background: white;
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 4px 4px 0px #5A565640;
      padding: 0.5rem;
      width: 300px;
      max-height: 80vh;
      overflow-y: scroll;
      display: flex;

      &::-webkit-scrollbar {
        width: 0px !important;
        height: 0px !important;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &>.mainMenu {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding: 0.5rem 0;

        &>.eachItem {
          display: flex;
          align-items: center;
          gap: 1rem;
          padding: 0.3rem 1rem;
          border-radius: 15px 0 0 15px;
          cursor: pointer;
          transition: all linear 0.3s;

          &>.imageDiv {
            // border: 1px solid #e5e5e5;
            // padding: 5px;
            border-radius: 50%;
            height: 2rem;
            width: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;

            &>img {
              width: 75%;
              object-fit: contain;
            }
          }

          &> :nth-child(2) {
            font-size: 0.9rem;
            font-weight: 400;
            white-space: nowrap;
          }
        }

        &>.eachItemSelected {
          background: #f1f4f6bf;
        }
      }
    }
  }

  &>.loginCard {

    display: flex;
    justify-content: space-between;
    max-width: 1150px;
    height: 656px;
    position: relative;

    &>.leftCard {
      width: 454px;

      padding: 3rem;
      background: white;
      border-radius: 15px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      &>img {
        height: 6rem;
        object-fit: contain;
        width: max-content;
      }

      &>.credentialsContainer {

        display: flex;
        flex-direction: column;
        gap: 1.4rem;

        &>.title {
          font-size:
            30px;
          font-weight: 520;
          color: #384455;
        }

        &>.letter {
          font-size:
            16px;
          font-weight: 400;
          color: #384455;
          line-height: 27px;
        }

        .labeltext {
          font-size:
            14px;
          font-weight: 500;
          color: #384455;
          line-height: 27px;
          margin-bottom: 5px;
        }

        .bottomMessage {
          font-size:
            14px;
          font-weight: 500;
          color: #384455;
          display: flex;
          justify-content: center;

          a {
            color: #1E4AE9 !important;
            font-weight: 500;
            margin-left: 2px;
          }

        }

        .inputContainer {
          border: 0.5px solid #D4D7E3;
          padding: 1rem;
          background: #d4d7e33d;
          font-size: 1rem;
          display: block;
          outline: none;
          font-weight: 400;
          border-radius: 5px;

          width: 100%;
          color:
            #384455;

          &::placeholder {
            color: #8897AD;

          }
        }

        .loginMessage {
          font-size: 1rem;
          font-weight: 500;
          color: #FC4F00;
          padding: 0.25rem 0;
        }

        .submitBtn {
          border-radius: 12px;
          /* max-width: 450px; */
          width: 100%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 400;
          color: white;
          background: var(--main-color);
          border: 1px solid var(--main-color);
          outline: none;
          border: none;
          cursor: pointer;
          transition: all linear 0.3s;
          background: #FC4F00;
          margin-top: 1.5rem;

          &:hover {
            scale: 1.05;
          }
        }

        .normalBtn {
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
          text-align: right;

          width: 100%;
          transition: all linear 0.3s;
          color: #1E4AE9;
          margin-top: 1rem;

          &:hover {
            text-decoration: underline;
          }
        }

        &>.seperator {
          font-weight: 400;
          font-size: 1rem;
          position: relative;
          max-width: 450px;
          width: 100%;
          text-align: center;
          color: #294957;

          &>span {
            background: white;
            padding: 0 0.5rem;
            position: relative;
            z-index: 1;
          }

          &::before {
            position: absolute;
            content: "";
            height: 1px;
            background: #e5e5e5;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .transparentBtn {
          border-radius: 12px;

          width: 100%;
          padding: 1rem;
          font-size: 1rem;
          outline: none;
          font-weight: 550;
          color: var(--font-color);
          color: #19376D;


          outline: none;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
          transition: all linear 0.3s;
          font-weight: 400;

          background: rgba(212, 215, 227, 0.2392156863);

          &>img {
            width: 1rem;
            height: 1rem;
          }

          &:hover {
            //  background: #e5e5e5;
            scale: 1.05;
          }
        }
      }
    }

    &>.rightCard {
      width: 40%;

      display: flex;
      justify-content: center;
      flex-direction: column;
      // align-items: center;

      // &>img {
      //   width: 7rem;
      //   object-fit: contain;
      // }    display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 47px;

      .logoicon {
        height: 126px;
        margin-bottom: 2.4rem;
        width: 22rem;
      }

      .divicon {
        display: flex;
        align-items: center;
        padding-left: 20px;
        margin-top: 2rem;
      }

      .desc {
        margin-top: 1.2rem;
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #ffffffb7;
        padding-left: 20px;
      }

      .supoort {

        font-size: 14px;
        font-weight: 500;
        line-height: 16.94px;
        text-align: left;
        color: #FFFFFF;
        position: relative;
        left: -18px;
      }

      .listicon {
        display: flex;
        align-items: center;
        position: relative;

      }

      .tool {
        width: 464px;
        padding-left: 20px;
        font-size: 42px;
        font-weight: 700;
        line-height: 50.51px;
        text-align: left;
        color: #FFFFFF;



      }
    }

    &>.bottomMessage {
      position: absolute;
      left: 0%;
      right: 0;
      bottom: -2.5rem;
      text-align: center;
      font-weight: 600;
      font-size: 1rem;

      &>a {
        background: var(--main-color);
        cursor: pointer;
        text-decoration: none;
        transition: all linear 0.3s;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &>.userRegistration {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      width: 100%;

      &>.userRegistrationContainer {
        width: 100%;

        &>.flexParent {
          display: flex;
          align-items: center;
          gap: 1rem;

          &>input {
            width: 50%;
            max-width: unset;
          }

          &>.checkDiv {
            border: none;
            display: flex;
            position: relative;

            &>input {
              border: none;
              width: -webkit-fill-available;
              max-width: unset;
            }

            &>.checkLight {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: red;
              position: absolute;
              right: 5px;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &>textarea {
            border: 0.5px solid #e5e5e5;
            padding: 1rem;
            background: #f5f5f5;
            font-size: 1rem;
            display: block;
            outline: none;
            font-weight: 450;
            border-radius: 5px;
            width: 50%;
            font-family: inherit;
            max-width: unset;
          }

          &>button {
            width: 50%;
            max-width: unset;
            display: flex;
            justify-content: center;
          }

          &>div {
            height: 3.25rem;
            width: 50%;

            &>div {
              background: #f5f5f5;
              border: 0.5px solid #e5e5e5;
              max-width: unset;
            }
          }

          &>.profileContainer {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: rgba(0, 0, 0, 0.9);
            font-size: 0.9rem;
            font-weight: 450;
            width: 50%;
            height: unset;

            &>.imageInput {
              width: -webkit-fill-available;
              background: none;
              border: none;

              img {
                width: 100%;
                height: 6rem;
                border-radius: 5px;
                border: 0.5px solid #e5e5e5;
              }

              &>.imageLabel {
                white-space: nowrap;
                text-align: center;
                margin-top: 1rem;
                color: rgba(0, 0, 0, 0.9);
                font-size: 0.9rem;
                font-weight: 450;
                cursor: pointer;
              }

              &>.emptyProfile {
                width: 100%;
                height: 6rem;
                border-radius: 5px;
                background: #f5f5f5;
                border: 0.5px solid #e5e5e5;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all linear 0.3s;

                &>img {
                  width: 3rem;
                  height: 3rem;
                  border: none;
                  transition: all linear 0.3s;
                }

                &:hover {
                  border: 0.5px solid var(--main-color);

                  &>img {
                    height: 4rem;
                    width: 4rem;
                  }
                }

                @keyframes heartbeat {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }

                  100% {
                    transform: scale(1);
                  }
                }
              }

              &>.emptyProfileLength {
                height: 6rem;
                width: 100%;
                border-radius: 5px;
                background: #f5f5f5;
                border: 0.5px solid #e5e5e5;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all linear 0.3s;

                &>img {
                  width: 3rem;
                  height: 3rem;
                  border: none;
                  transition: all linear 0.3s;
                }

                &:hover {
                  border: 0.5px solid var(--main-color);

                  &>img {
                    height: 4rem;
                    width: 4rem;
                  }
                }

                @keyframes heartbeat {
                  0% {
                    transform: scale(1);
                  }

                  50% {
                    transform: scale(1.2);
                  }

                  100% {
                    transform: scale(1);
                  }
                }
              }
            }

            &>.editIcon {
              all: unset;
              cursor: pointer;
            }
          }
        }
      }
    }

    &>.backButton {
      position: absolute;
      white-space: nowrap;
      left: 1rem;
      top: 1rem;
      text-decoration: underline;
      cursor: pointer;
      font-size: 0.85rem;
      font-weight: 550;
      color: var(--font-color);
      transition: all linear 0.3s;

      &:hover {
        scale: 1.05;
      }
    }
  }
}

@media only screen and (max-width: 1300px) {

  .loginPage {



    &>.loginCard {


      height: 557px;

      &>.leftCard {

        padding: 2rem;

        &>.credentialsContainer {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }
      }

      &>.rightCard {
        width: 40%;

        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;

        // &>img {
        //   width: 7rem;
        //   object-fit: contain;
        // }    display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: 47px;

        .logoicon {
          height: 126px;
          margin-bottom: 2.4rem;
          width: 22rem;
        }

        .divicon {
          display: flex;
          align-items: center;
          padding-left: 20px;
          margin-top: 2rem;
        }

        .desc {
          margin-top: 1.2rem;
          font-size: 18px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #ffffffb7;
          padding-left: 20px;
        }

        .supoort {

          font-size: 14px;
          font-weight: 500;
          line-height: 16.94px;
          text-align: left;
          color: #FFFFFF;
          position: relative;
          left: -18px;
        }

        .listicon {
          display: flex;
          align-items: center;
          position: relative;

        }

        .tool {
          width: 464px;
          padding-left: 20px;
          font-size: 42px;
          font-weight: 700;
          line-height: 50.51px;
          text-align: left;
          color: #FFFFFF;



        }
      }






    }
  }

}