.selectdconsutantdrop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 47px;
    border: 1px solid #ddd;
    cursor: pointer;
    padding: 0rem 1.5rem;

    .flexbox {
        display: flex;
        align-items: center;
    }

    .title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #5F6163;

    }

    svg {
        cursor: pointer;

    }

    img {
        margin-right: 12px;
        width: 29px;
        border-radius: 50rem;
        height: 29px;

    }

}

.parentsessionsetion {
    display: flex;
    align-items: self-start;
    height: calc(100vh - 177px);
    justify-content: space-between;

    overflow: hidden;

    .namewidget {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A5656;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        svg {
            cursor: pointer;
        }

    }

    .widgetparent {
        border: 1px solid #E9EBF3;
        padding: 2rem;
        margin-bottom: 1.5rem;
        border-radius: 10px;
        position: relative;
    }

    .saveSection {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        div {
            border: 1px solid #E9EBF3;
            width: 158px;
            height: 36px;

            border-radius: 5;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 1rem;

            &:hover {
                transform: scale(1.03);
            }

        }
    }

    .questionsection {
        margin-top: 2rem;
        position: relative;

        &:nth-child(1) {
            margin-top: 0rem;
        }

        .queTitledrop {
            border: 1px solid #E9EBF3;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            height: 36px;
            padding: 0 10px;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            width: 200px;

            svg {
                position: absolute;
                right: 10px;
            }
        }

        .dropdonwlistdata {
            border: 1px solid #E9EBF3;
            border-radius: 10px;
            border-radius: 10px;
            width: 200px;
            padding: 1.5rem;
            position: absolute;
            background: white;
            z-index: 1;
            top: 4.5rem;

            div {
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;
                color: #5A5656;
                cursor: pointer;
                margin-top: 1rem;

                &:nth-child(1) {
                    margin-top: 0rem;
                }

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        .queTitle {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            margin-bottom: 1rem;
        }

        .queAnswer {
            border: 1px solid #E9EBF3;
            height: 36px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            width: 158px;
        }

        .queAnswerinput {
            border: 1px solid #E9EBF3;
            height: 36px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: left;
            color: #5A5656;
            width: 200px;

            &:focus-visible {
                outline: -webkit-focus-ring-color auto 0px !important;
            }
        }
    }

    img {
        width: 47px;
        height: 47px;
        border-radius: 54rem;

        margin-right: 14px;
    }


}

.topimagesectionsession {
    .soecond {
        width: calc(100% - 324px);
    }

    .topcontent {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        justify-content: space-between;
    }

    .first {
        width: 295px;
    }

    .contents {
        display: flex;
        align-items: self-start;
        gap: 2rem;
    }

    .title {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #5F6163;

    }

    svg {
        cursor: pointer;

    }

    img {
        margin-right: 12px;
        width: 29px;
        border-radius: 50rem;
        height: 29px;
    }



    .inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .parenttools {
            display: flex;
            align-items: center;
            gap: 2rem;
        }

        .parentimg {
            display: flex;
            align-items: center;
        }

        .toolbox {


            border: 1px solid #E9EBF3;

            height: 36px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-radius: 5px;
            cursor: pointer;

            .line {
                background: #E9EBF3;
                height: 29px;
                width: 1px;

                padding: 0 0rem !important;
            }

            div {
                font-family: Montserrat;
                padding: 0 2rem;
                font-size: 12px;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: center;
                color: #5A5656;
                text-transform: capitalize;
            }
        }

        .boxes {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: #5A5656;
            padding: 0 2rem;
            border: 1px solid #E9EBF3;

            height: 36px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
                transform: scale(1.03);
            }


        }



        .imagecontroller {
            display: flex;
            align-items: center;

            svg {
                cursor: pointer;
                margin-right: 13px;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }
    }

    .name {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A5656;
    }



    .subname {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A5656;

    }

    .parenttools {
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: end;
        // margin-bottom: 3rem;
    }

    .parentimg {
        display: flex;
        align-items: center;
    }

    .toolbox {


        border: 1px solid #E9EBF3;

        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        cursor: pointer;

        .line {
            background: #E9EBF3;
            height: 29px;
            width: 1px;

            padding: 0 0rem !important;
        }

        div {
            font-family: Montserrat;
            padding: 0 2rem;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: #5A5656;
            text-transform: capitalize;
        }
    }

}

.rightsideSession {
    width: calc(100% - 324px);
    margin-top: 0;
    // background: white;
    // padding-top: 1.5rem;
    // padding-left: 20px;
    height: 100%;

    .innerSectionSession {
        height: calc(100% - 4rem);
        background: white;
        padding: 21px;
        /* padding-top: 0; */
        padding-top: 2rem;

        overflow: scroll;
        margin-top: 1.5rem;
        position: relative;
    }


    .boxes {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
        color: #5A5656;
        padding: 0 2rem;
        border: 1px solid #E9EBF3;

        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            transform: scale(1.03);
        }


    }

    .parenttools {
        display: flex;
        align-items: center;
        gap: 2rem;
        justify-content: end;
        margin-bottom: 3rem;
    }

    .parentimg {
        display: flex;
        align-items: center;
    }

    .toolbox {


        border: 1px solid #E9EBF3;

        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        cursor: pointer;

        .line {
            background: #E9EBF3;
            height: 29px;
            width: 1px;

            padding: 0 0rem !important;
        }

        div {
            font-family: Montserrat;
            padding: 0 2rem;
            font-size: 12px;
            font-weight: 600;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
            color: #5A5656;
            text-transform: capitalize;
        }
    }



    .contents {
        display: flex;
        align-items: self-start;
        gap: 2rem;
    }
}

.leftsideSession {
    background: white;
    height: 100%;
    height: 100%;
    width: 295px;
    /* margin-top: 1rem; */
    padding-left: 12px;
    padding-top: 1.5rem;

    .titel {
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A5656;
        margin-top: 1rem;
        margin-bottom: 1.5rem;

    }

    .timebox {
        width: 268px;
        height: 52px;
        border-radius: 5px;
        border: 1px solid #E9EBF3;
        background: #FFFFFF;
        color: #5A5656;
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;
        cursor: pointer;

        .time {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            text-align: center;
            color: #C57E50;
            width: 100%;
            text-transform: capitalize;
        }

        .line {
            background: #E9EBF3;
            height: 35px;
            width: 1px;

        }
    }
}


.ToggleSwitch {
    background: #fff;
    border: 1px solid #D9D9D9;
    height: 1.6em;
    width: 4em;
    border-radius: 1em;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
    position: absolute;
    top: 2rem;
    right: 2rem;

    .knob {
        position: relative;
        width: 1.5em;
        height: 1.5em;
        background: #666;

        cursor: pointer;
        border-radius: 50%;
        left: 0em;
        transition: left 0.3s ease-out;

        &.active {
            left: 2.3em;
            background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);

        }
    }

}