.booking-dashboard {

    height: 100%;
    width: 100%;
    padding-bottom: 0;

    .linestring {
        width: 100%;
        margin-top: 2.5rem;
        margin-bottom: 2rem;
        background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);
        height: 1px;
    }

    .dropdownParentSectioncutom {
        position: relative;

        label {
            font-family: Montserrat;
            font-size: 11px;
            font-weight: 600;
            line-height: 13px;
            letter-spacing: 0em;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 16px;
            width: 89px;
            height: 32px;
            top: 8px;
            border-radius: 5px;
            cursor: pointer;
            background: #FFFFFF;
            border: 0.5px solid #C57E50;

            &:hover {
                transform: scale(1.02);
            }
        }
    }

    .righside {
        border: 0.5px solid #E9EBF3;
        background: white;
        width: 100%;
        padding-bottom: 1.5rem;
        border-radius: 5px;
        height: calc(100vh - 126px);
        overflow: scroll;

        .tabls {
            display: flex;
            align-items: start;
            gap: 2rem;
            border-bottom: 0.5px solid #ddd;
            padding: 1.5rem;
            padding-bottom: 0;

            div {
                cursor: pointer;
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #5F6163;
                margin-bottom: 0rem;
                cursor: pointer;

                &:hover {
                    transform: scale(1.03);
                }

            }

            .line {
                width: 100%;
                margin-top: 0.7rem;
                background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);
                height: 2px;
            }
        }

        table {
            width: 100%;

            padding: 1.5rem;
            padding-top: 0;

        }

        .submitelable {
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0em;
            text-align: center;
            width: 96px;
            height: 28px;

            border-radius: 5px;
            background: #34A853;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;

            &:hover {
                transform: scale(1.02);
            }
        }

        title {
            font-family: Montserrat;
            font-size: 10px;
            font-weight: 400;
            line-height: 34px;
            letter-spacing: 0em;
            text-align: left;
            color: #5F6163;
        }

        tr {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #E9EBF3;
            margin-top: 1rem;
            border-radius: 5px;
            padding: 1rem;
            cursor: pointer;

            &:hover {
                transform: scale(1.02);
            }

            &:nth-child(1) {
                border: 0px solid #E9EBF3;
                margin-top: 0rem;
                border-radius: 0px;

                &:hover {
                    transform: scale(1);
                }
            }

            &:nth-child(2) {

                margin-top: 0rem;

            }
        }

        td {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 16.6666667%;
            flex-wrap: wrap-reverse;
            padding: 0 0.5rem;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 14.2857142857%;

            &:nth-last-child(1) {
                align-items: center;
            }



        }

        th {
            width: 14.2857142857%;

            font-family: Montserrat;
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            letter-spacing: 0em;
            text-align: left;
            color: #5F6163;
            padding: 0 0.5rem;

            &:nth-last-child(1) {
                display: flex;
                align-items: center;
                justify-content: center;
            }

        }
    }

    .submit {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;

        height: 47px;
        background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);

        border-radius: 5px;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
        cursor: pointer;
        color: #fff;

        &:hover {
            transform: scale(1.02);
        }
    }

    .input {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #5F6163;
        font-style: normal;
        font-weight: 400;
        padding-left: 12px;
        border: 1px solid #E5E5E5;

        width: 100%;

        height: 47px;
        border-radius: 5px;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
            border: 1px solid #E5E5E5
        }
    }

    .scroll-bottom-div {
        padding: 0rem 1.5rem;
        height: calc(100vh - 20px);
        /* OVERFLOW: SCROLL; */
        overflow: scroll;

        .labelbuttonbokin {
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0em;
            text-align: left;
            color: #5F6163;
            font-style: normal;
            font-weight: 400;
            padding-left: 12px;
            border: 1px solid #E5E5E5;

            width: 100%;
            display: flex;
            align-items: center;
            height: 47px;
            border-radius: 5px;
            cursor: pointer;
            margin-top: 1.5rem;

            &:hover {
                transform: scale(1.02);
            }
        }
    }

    .bottomleftside {
        width: 100%;
        margin-top: 1.5rem;
        border-radius: 10px 10px 0px 0px;
        border: 0.5px solid #E9EBF3;
        background: white;
    }

    .createbooking {
        border-radius: 10px 10px 0px 0px;
        height: 100%;
        background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);

        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;



    }

    .dateinput {
        height: 40px;
        position: absolute;
        width: 99%;
        left: 1px;
        padding-left: 12px;
        border: 0px solid;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #5F6163;
        font-style: normal;
        font-weight: 400;

        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px !important;
            border: 0px solid;
        }
    }

    .dropdown-planb {
        border-radius: 5px;
        background: #fff;
        height: 47px;
        width: 100%;
        color: #5F6163;
        font-family: Montserrat;
        padding: 0 0.7rem;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        border: 1px solid #E5E5E5;

        svg {
            margin-left: 15px;
        }
    }

    .titleseleted {
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: #5F6163;
        margin-bottom: 0.4rem;
        margin-top: 1.2rem;
    }

    .dropdwonlist {
        border-radius: 5px;
        background: #fff;
        padding: 1rem;
        position: absolute;
        top: 50px;
        width: 100%;
        left: 0;
        margin: auto;
        right: 0;
        z-index: 1;
        white-space: break-spaces;
        border: 1px solid #E5E5E5;

        .listitemName {
            display: flex;
            align-items: center;
            color: #5F6163;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-bottom: 1rem;
            cursor: pointer;

            &:nth-last-child(1) {
                margin-bottom: 0rem;
            }

            img {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }

            &:hover {
                transform: scale(1.01);
            }

        }
    }

    .toggledrop {
        position: absolute;
        z-index: 9999;
        width: 100%;

    }

    .dropdownParentSection {
        position: relative;
        width: 206px;
    }

    .cards-booking {
        display: flex;
        align-items: center;
        gap: 2rem
    }

    .inside-card {
        border: 0.5px solid #E9EBF3;
        background: #FFFFFF;
        width:
            192px;
        padding: 0.7rem 1.5rem;
        border-radius: 10px;

        border: 0.5px solid #E9EBF3;

        &:hover {
            transform: scale(1.03);
        }
    }

    .title {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        color: #5A5656;

    }

    .value {
        font-family: Montserrat;
        font-size: 25px;
        font-weight: 700;
        line-height: 35px;
        color: #5A5656;

        letter-spacing: 0em;
        text-align: left;

    }

    .label {
        width: 100%;
        height: 28px;
        border-radius: 5px;
        font-family: Montserrat;
        font-size: 10px;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        cursor: pointer;
        border: 0.5px solid #E9EBF3;

        &:hover {
            transform: scale(1.03);
        }
    }
}


.listofitembooking {
    border: 1px solid #E5E5E5;
    width: 100%;
    height: 47px;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #5F6163;
    cursor: pointer;

    &:hover {
        transform: scale(1.03);
    }



}