.crmUsers {
  padding: 2rem;
  height: 100%;
  width: 100%;
  padding-bottom: 0;

  & > .allLeads {
    border: 1px solid #e5e5e5;
    height: 100%;
    overflow: hidden;
    background: white;
    border-bottom: 0;
    overflow-y: hidden;

    & > .nav {
      border-bottom: 1px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > .filterBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 51px;
        height: 50px;
        border-right: 1px solid #e5e5e5;
        position: relative;

        & > svg {
          fill: var(--font-color);
          transition: all linear 0.3s;

          &:hover {
            fill: var(--main-color);
          }
        }

        & > .dropdownContent {
          position: absolute;
          top: 70%;
          left: 55%;
          padding: 1rem;
          background: white;
          z-index: 6;
          border: 1px solid #e1e1e1;
          box-shadow: 0px 4px 4px 0px #5A565640;
          border-radius: 10px;
          // min-width: calc(max-content + 4rem);
          min-width: 250px;

          & > .eachFilterItem {
            padding: 0.7rem 0.5rem;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
            white-space: nowrap;
            // padding-right: 4rem;

            & > svg {
              rotate: -90deg;
            }

            &:hover {
              color: var(--main-color);
            }
          }
        }
      }

      & > .actionBtn {
        display: flex;
        align-items: center;
        gap: 1rem;

        & > div {
          border: 1px solid #e5e5e5;
          border-radius: 35px;
          padding: 0.5rem 2.5rem;
          cursor: pointer;
          font-size: 0.9rem;
          transition: all ease-in 0.2s;

          &:hover {
            background: #e5e5e5;
          }
        }
      }

      & > .actionBtnsDownload {
        & > div {
          font-size: 0.9rem;
          font-weight: 500;
          cursor: pointer;
          transition: all linear 0.3s;

          &:hover {
            background: var(--main-color);
            color: white;
          }
        }
      }
    }

    & > .tableContainer {
      overflow-y: auto;
      height: calc(100% - 2rem);
      width: 100%; /* Set the container width to 100% or any other desired value */
      overflow-x: auto; /* Allow horizontal scrolling */
      // border-left: 0.25px solid #e5e5e5;
      // border-right: 0.25px solid #e5e5e5;
      position: relative;
      padding-bottom: 1.5rem;

      &::-webkit-scrollbar {
        display: block;
        width: 7px; /* Set the width of the table's scrollbar */
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        height: 7px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(
          --font-color
        ); /* Set the color of the scrollbar thumb */
        border-radius: 5px; /* Set the border-radius of the thumb */
        height: 80px;
        transition: all linear 0.3s;
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(
          --main-color
        ); /* Set the color when the scrollbar thumb is hovered */
      }

      table {
        border-collapse: collapse;
        min-width: 240%; /* Set the table width to 110% to ensure horizontal scrollbar */
        // margin-bottom: 20px;
        table-layout: fixed; /* Ensures equal column width for horizontally scrolling table */
        width: max-content;

        // tbody {
        //   & > :last-child {
        //     & > td {
        //       border-bottom: none;
        //     }
        //   }
        // }

        th,
        td {
          border: 1px solid #e5e5e5;
          text-align: left;
          white-space: normal;
          word-wrap: break-word;
          padding: 0.75rem 1rem;
          position: relative;
          display: flex;
          align-items: center;
          border-right: none;
          border-top: none;
          font-size: 0.85rem;

          & > input {
            width: 1.25rem;
            height: 1.25rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        th {
          white-space: nowrap;
          font-weight: 550;
          font-size: 0.85rem;
          padding: 0.7rem 1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > svg {
            cursor: pointer;
            fill: var(--main-color);
          }
        }

        thead {
          & > tr {
            & > th {
              border-top: none;
            }
          }
        }

        tr {
          & > :nth-child(1) {
            border-left: none;
            width: 50px;
          }

          & > :last-child {
            border-right: none;
          }
        }

        tr {
          display: grid;
          grid-template-columns: min-content repeat(15, 1fr);
        }

        tr > :first-child {
          /* Set the width you want for the first child */
          width: 50px; /* Replace 200px with your desired width */
          /* Position the first child in the first column */
          grid-column: 1;
        }

        .detailedItem {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: flex-start;

          & > :nth-child(1) {
            padding: 0.5rem;
            border: 0.5px solid #e5e5e5;
            border-radius: 50%;
            width: 3rem;
            height: 3rem;
            display: flex;
            justify-content: center;
            align-items: center;

            & > img {
              width: 70%;
              height: 70%;
            }
          }

          & > :nth-child(2) {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            & > :nth-child(1) {
              font-size: 0.9rem;
              font-weight: 550;
              white-space: nowrap;
            }
            & > :nth-child(2) {
              font-size: 0.8rem;
              font-weight: 400;
            }
          }
        }

        td {
          position: relative;
          transition: all linear 0.3s;

          &:hover {
            background: #8080800d;
          }

          & > .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem 0;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #5A565640;
            border-radius: 10px;
            min-width: 250px;

            & > .eachFilterItem {
              padding: 0.7rem 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              width: 100%;
              // padding-right: 4rem;

              & > svg {
                rotate: -90deg;
              }

              & > .subDropdown {
                display: none;
                min-width: 250px;
                left: 100%;
                top: 0;
                color: var(--font-color);
              }

              &:hover {
                color: var(--main-color);

                & > .subDropdown {
                  display: block;
                }
              }
            }
          }

          .dropdownContent {
            position: absolute;
            top: 70%;
            left: 55%;
            padding: 1rem 0;
            background: white;
            z-index: 6;
            border: 1px solid #e1e1e1;
            box-shadow: 0px 4px 4px 0px #5A565640;
            border-radius: 10px;
            min-width: 250px;

            & > .eachFilterItem {
              padding: 0.7rem 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              cursor: pointer;
              white-space: nowrap;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              width: 100%;
              // padding-right: 4rem;

              & > span {
                display: flex;
                gap: 10px;
                align-items: center;

                & > svg {
                  height: 1rem;
                  width: 1rem;
                }
              }

              & > svg {
                rotate: -90deg;
              }

              & > .subDropdown {
                display: none;
                min-width: 250px;
                left: 100%;
                top: 0;
                color: var(--font-color);
              }

              &:hover {
                color: var(--main-color);

                & > .subDropdown {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
