.settings {
  padding: 2rem;
  height: calc(100% - 2rem);
  width: 100%;
  padding-bottom: 0;
  display: flex;
  gap: 1.5rem;

  &>div {
    background: white;
    padding: 2rem;
    height: 100%;
  }

  &>.leftMenu {
    width: 400px;

    &>.heading {
      font-size: 1.25rem;
      font-weight: 550;
    }

    &>.menuItems {
      display: flex;
      flex-direction: column;
      gap: 1.75rem;
      margin-top: 1.6rem;

      &>div {
        font-size: 0.9rem;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          color: var(--main-color);
        }
      }

      &>.selectedItem {
        font-weight: 550;
        color: var(--main-color);
      }
    }
  }

  &>.rightDashboard {
    width: -webkit-fill-available;
    overflow-y: scroll;
    max-width: 1200px;
    margin: auto;
    border-radius: 10px;
  }
}

.profileParent {



  &>.profileName {
    font-size: 1.5rem;
    font-weight: 550;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 2rem;

    &>img {
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border: 1px solid #f5f5f5;
    }
  }

  &>.profileDetails {
    padding-right: 1rem;

    .eachProfileItem {
      &>.title {
        font-size: 0.9rem;
        color: var(--font-color);
        padding-bottom: 1rem;
        font-weight: 500;
        white-space: nowrap;
      }

      &>.inputValue {
        position: relative;
        width: 100%;

        &>div {
          height: 100%;
          width: 100%;

          &>.imageBox {
            border: 0.5px solid #e7e7e7;
            border-radius: 5px;
            // height: 100%;
            aspect-ratio: 1/1;

            &>img {
              border-radius: 5px;
              width: 100%;
              height: 100%;
            }
          }

          &>.valueBox {
            padding: 1rem 1.4rem;
            background: #f5f5f5;
            border-radius: 10px;
            font-weight: 400;
            margin-top: 0.5rem;
            font-size: 0.9rem;
          }

          &>.inputBox {
            padding: 1rem 1.4rem;
            border-radius: 10px;
            font-weight: 550;
            margin-top: 0.5rem;
            font-size: 0.9rem;
            outline: none;
            border: 0.5px solid #e7e7e7;
            width: 100%;

            &:focus {
              border: 1px solid black;
            }
          }

          &>.editContainer {
            &>.editIcon {
              position: absolute;
              top: -15px;
              right: -15px;
              padding: 8px;
              background: white;
              height: 2rem;
              width: 2rem;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              border: 0.5px solid #e7e7e7;

              &>img {
                height: 0.8rem;
                width: 0.8rem;
              }
            }

            &>.buttonsContainer {
              display: flex;
              gap: 1rem;
              align-items: center;
              margin-top: 1.5rem;

              &>button {
                all: unset;
                padding: 0.8rem 2rem;
                border-radius: 5px;
                font-size: 0.9rem;
                font-weight: 500;
                cursor: pointer;
                color: white;
                width: 50%;
                text-align: center;
              }

              &> :nth-child(1) {
                background: #ea4335;
              }

              &> :nth-child(2) {
                background: #34a853;
              }
            }
          }
        }
      }
    }

    &>.topContainer {
      display: grid;
      grid-template-columns: 0.45fr 1fr;
      padding-top: 2rem;
      // border-top: 1px solid #e9ebf3;
      gap: 2rem;

      &>div {
        &>img {
          // width: 250px;
        }
      }

      &>.valuesDiv {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1.5rem;

        &>div {
          width: 100%;
        }
      }
    }

    &>.bottomProfile {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      padding: 2rem 0;
      gap: 2rem;
    }
  }
}

.ownerContainer {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &>.ownerItems {
    border: 2px solid #f5f5f5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
    height: 4rem;
    padding: 0 2rem;
    transition: all linear 0.3s;

    &>img {
      border: 1.5px solid white;
      border-radius: 50%;
    }

    &>div {
      width: 100%;

      &>span {
        height: 100%;
        display: block;
      }
    }

    &>.profileDetails {
      display: flex;
      gap: 10px;
      align-items: center;
      font-size: 0.9rem;
      width: max-content;

      &>img {
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
        border: 0.5px solid #e5e5e5;
      }
    }

    &>.buttonsContainer {
      display: flex;
      gap: 1rem;
      align-items: center;
      padding: 10px 0;
      width: max-content;
      padding: 0;

      &>div {
        height: 100%;
        border-radius: 5px;
        padding: 10px 1rem;
        border: 1px solid #e9ebf3;
        font-size: 0.8rem;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          background: var(--main-color);
          color: white;
        }
      }
    }
  }

  &>.ownerItemsHover {
    &:hover {
      background: var(--main-color);
      color: white;
    }
  }

  &>.line {
    height: 1px;
    background: #e5e5e5;
    width: 100%;
    margin: 1rem 0;
  }
}

.createNewOrganization {
  &>.leftCard {
    width: 60%;
    // padding: 3rem;
    border-right: 1px solid #e7e7e7;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &>img {
      height: 6rem;
      object-fit: contain;
      width: max-content;
    }

    &>.credentialsContainer {
      display: flex;
      flex-direction: column;
      gap: 1.7rem;

      &>.title {
        font-size: 1.5rem;
        font-weight: 520;
        margin-bottom: 2rem;
      }

      .inputContainer {
        border: 0.5px solid #e5e5e5;
        padding: 1rem;
        background: #f5f5f5;
        font-size: 1rem;
        display: block;
        outline: none;
        font-weight: 400;
        border-radius: 5px;
        max-width: 450px;
        width: 80%;
      }

      .loginMessage {
        font-size: 1rem;
        font-weight: 500;
        color: #d6a86b;
        padding: 0.25rem 0;
      }

      .submitBtn {
        border-radius: 5px;
        max-width: 450px;
        width: 80%;
        padding: 1rem;
        font-size: 1rem;
        outline: none;
        font-weight: 400;
        color: white;
        background: var(--main-color);
        border: 1px solid var(--main-color);
        outline: none;
        border: none;
        cursor: pointer;
        transition: all linear 0.3s;

        &:hover {
          scale: 1.05;
        }
      }

      .normalBtn {
        font-size: 1rem;
        font-weight: 500;
        cursor: pointer;
        text-align: center;
        max-width: 450px;
        width: 80%;
        transition: all linear 0.3s;

        &:hover {
          text-decoration: underline;
        }
      }

      &>.seperator {
        font-weight: 600;
        font-size: 1rem;
        position: relative;
        max-width: 450px;
        width: 80%;
        text-align: center;

        &>span {
          background: white;
          padding: 0 0.5rem;
          position: relative;
          z-index: 1;
        }

        &::before {
          position: absolute;
          content: "";
          height: 1px;
          background: #e5e5e5;
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .transparentBtn {
        border-radius: 5px;
        max-width: 450px;
        width: 80%;
        padding: 1rem;
        font-size: 1rem;
        outline: none;
        font-weight: 550;
        color: var(--font-color);
        border: 1px solid #e5e5e5;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        transition: all linear 0.3s;

        &>img {
          width: 1rem;
          height: 1rem;
        }

        &:hover {
          background: #e5e5e5;
        }
      }
    }
  }

  &>.userRegistration {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;

    &>.userRegistrationContainer {
      width: 100%;

      &>.flexParent {
        display: flex;
        align-items: center;
        gap: 1rem;

        &>input {
          width: 50%;
          max-width: unset;
        }

        &>textarea {
          border: 0.5px solid #e5e5e5;
          padding: 1rem;
          background: #f5f5f5;
          font-size: 1rem;
          display: block;
          outline: none;
          font-weight: 450;
          border-radius: 5px;
          width: 50%;
          font-family: inherit;
          max-width: unset;
        }

        &>button {
          width: 50%;
          max-width: unset;
          display: flex;
          justify-content: center;
        }

        &>div {
          height: 3.25rem;
          width: 50%;

          &>div {
            background: #f5f5f5;
            border: 0.5px solid #e5e5e5;
            max-width: unset;
          }
        }

        &>.profileContainer {
          display: flex;
          align-items: center;
          gap: 1rem;
          color: rgba(0, 0, 0, 0.9);
          font-size: 0.9rem;
          font-weight: 450;
          width: 50%;
          height: unset;

          &>.imageInput {
            width: -webkit-fill-available;
            background: none;
            border: none;

            img {
              width: 100%;
              height: 6rem;
              border-radius: 5px;
              border: 0.5px solid #e5e5e5;
            }

            &>.imageLabel {
              white-space: nowrap;
              text-align: center;
              margin-top: 1rem;
              color: rgba(0, 0, 0, 0.9);
              font-size: 0.9rem;
              font-weight: 450;
              cursor: pointer;
            }

            &>.emptyProfile {
              width: 100%;
              height: 6rem;
              border-radius: 5px;
              background: #f5f5f5;
              border: 0.5px solid #e5e5e5;
              display: flex;
              justify-content: center;
              align-items: center;

              &>img {
                width: 3rem;
                height: 3rem;
                border: none;
              }

              @keyframes heartbeat {
                0% {
                  transform: scale(1);
                }

                50% {
                  transform: scale(1.2);
                }

                100% {
                  transform: scale(1);
                }
              }
            }

            &>.emptyProfileLength {
              height: 6rem;
              width: 100%;
              border-radius: 5px;
              background: #f5f5f5;
              border: 0.5px solid #e5e5e5;
              display: flex;
              justify-content: center;
              align-items: center;

              &>img {
                width: 3rem;
                height: 3rem;
                border: none;
              }

              @keyframes heartbeat {
                0% {
                  transform: scale(1);
                }

                50% {
                  transform: scale(1.2);
                }

                100% {
                  transform: scale(1);
                }
              }
            }
          }

          &>.editIcon {
            all: unset;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.profileName {
  font-size: 1.5rem;
  font-weight: 550;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;
  text-transform: capitalize;

  &>img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    border: 1px solid #f5f5f5;
  }
}



.perscribeEditior {
  padding-top: 2rem;
  display: flex;
  // flex-direction: column;
  gap: 2rem;
  align-items: self-start;
  justify-content: space-between;
  height: calc(100vh - 18rem);
  overflow: scroll;

  .leftside {
    display: flex;
    flex-direction: column;
    width: 42%;
    height: 99%;
    overflow: scroll;
    width: 42%;
  }

  .title {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    color: #5A5656;
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    transition: all linear 0.15s;

    svg {
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        transform: scale(1.01) translateX(1px);
      }
    }
  }

  .Addto {
    background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);
    width: 100%;
    height: 54px;

    border-radius: 10px;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    margin-top: 1.5rem;
    color: #fff;
    align-items: center;
    justify-content: center;
    display: flex;

    &:hover {
      transform: scale(1.01);
    }


  }

  .tablesection {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1.5rem;

    div {
      border: 1px solid #E5E5E5;
      width: 206px;
      height: 58px;
      padding-left: 1rem;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      line-height: 15.85px;
      text-align: left;
      color: #5A5656;
      cursor: pointer;

      &:hover {
        transform: scale(1.01);
      }
    }
  }

  .selectdiv {
    font-family: Montserrat;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    line-height: 15.85px;
    text-align: left;
    color: #5A5656;
    text-decoration: underline;
    margin-top: 10px;
    margin-bottom: 1.5rem;
  }

  .draginput {

    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    text-align: left;
    color: #5A5656;
    width: 100%;
    height: 58px;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    padding: 0 1.2rem;

  }

  .rightsubdiv {
    padding-top: 1.5rem;
    overflow: scroll;
    height: calc(100% - 6rem);
    margin-bottom: 36rem;
  }

  .rightside {
    border: 1px solid #E5E5E5;
    height: calc(100% + 0px);
    width: 50%;
    padding: 1.2rem;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
    color: #5A5656;
    border-radius: 15px;

    position: relative;

    .bottomlabel {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      align-items: start;

      .leftside {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        background: linear-gradient(90deg, #0B2447 0%, #0D264C 31.1%, #122E5A 67.68%, #19376D 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 50%;
        height: 54px;

        border-radius: 0px 0px 0px 15px;


      }


      .rightside {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 50%;
        height: 54px;

        border-radius: 0px 0px 0px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        width: 50%;
        height: 54px;

        border-radius: 0px 0px 15px 0px;
      }

    }
  }

  .eachOptionright {
    font-size: 1rem;
    padding: 1.2rem 1.2rem;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    font-weight: 500;
    cursor: pointer;
    transition: all linear 0.15s;
    margin-bottom: 0.8rem;
    // display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      transform: scale(1) translateY(-5px);
    }
  }

  .questionsection {
    margin-top: 1rem;
  }

  .close {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.63px;
    text-align: center;
    color: #5A5656;
    width: 97px;
    height: 34px;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    &:hover {
      transform: scale(1.02);
    }
  }

  .closesection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .questionsectBox {
    width: 100%;
    border: 1px solid #E5E5E5;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    //display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0.8rem 1rem;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    // background: #FFF7F3;

    &:nth-child(1n) {
      background: #F3F4F6;
    }

    &:nth-child(2n) {

      background: #FFF7F3;
    }

    &:hover {
      transform: scale(1) translateY(-2px);
    }

    svg {
      margin-left: 8px;
    }
  }

  .questionsectionName {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: #5A5656;
    margin-bottom: 1rem;

  }

  .listItem {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.85px;
    text-align: left;
    color: #5A5656;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .eachOption {
    font-size: 1rem;
    padding: 1.2rem 1.2rem;
   
    border-radius: 7px;
    font-weight: 500;
    cursor: pointer;
    transition: all linear 0.15s;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      transform: scale(1) translateY(-5px);
    }
  }
}