.preception-page {
    display: flex;
    align-items: start;
    justify-content: space-between;
    padding: 1rem;
    height: calc(100% - 14rem);

    .flex-ingedient {
        display: flex;
        align-items: start;
        width: 100%;
        padding: 0 0.5rem;
        background: #FFF7F3;
        border-radius: 6px;
    }

    .inputtectfilled {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
        color: #5A5656;
        border: 0px solid;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background: #FFF7F3;
        height: 22px;
        padding-left: 6px;
    }

    .textml {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
        text-align: right;
        color: #5A5656;

    }

    .amountSection {
        display: flex;
        align-items: self-start;
        justify-content: space-between;

    }

    .divingredient {
        width: 48%;
    }

    .titletext {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
        color: #5A5656;

    }

    .mllitter {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 25px;
        color: #5A5656;
        border: 0px solid;
        width: max-content;
        height: 100%;
        display: flex;
        align-items: center;
        background: #FFF7F3;
        height: 22px;
        padding-left: 6px;
    }

    .lifesidedata {
        height: calc(100% - 99px);
        overflow: scroll;
    }

    .draginput {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 15.85px;
        text-align: left;
        color: #5A5656;
        width: 100%;
        height: 58px;
        border: 1px solid #E5E5E5;
        border-radius: 10px;
        padding: 0 1.2rem;

        &::placeholder {
            color: #5F6163 !important;
            opacity: 1 !important;
        }
    }

    .Addto {
        background: linear-gradient(0deg, #FC4F00 0%, #F79540 100%);
        width: 100%;
        height: 54px;
        border-radius: 10px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 19.5px;
        text-align: center;
        margin-top: 1.5rem;
        color: #fff;
        align-items: center;
        justify-content: center;
        display: flex;
    }


    .selectdiv {
        font-family: Montserrat;
        font-size: 13px;
        font-style: italic;
        font-weight: 500;
        line-height: 15.85px;
        text-align: left;
        color: #5A5656;
        text-decoration: underline;
        margin-top: 10px;
        margin-bottom: 1.5rem;
    }

    .tablesection {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.5rem;
    }

    .title {
        font-family: Montserrat;
        font-size: 20px;
        font-weight: 600;
        line-height: 24.38px;
        text-align: left;
        color: #121212;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
        transition: all linear 0.15s;


        svg {
            margin-right: 10px;
            cursor: pointer;
        }


    }

    .flex-box-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    .submit {

        font-size: 14px;
        background: #37B12B;

        height: 40px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 48%;
        border-radius: 6px;

        &:hover {
            transform: scale(1.03);
        }

        svg {
            margin-right: 6px;

        }
    }

    .add {

        font-size: 14px;
        background: #19376D;
        height: 40px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 48%;
        border-radius: 6px;

        &:hover {
            transform: scale(1.03);
        }

        svg {
            margin-right: 6px;

        }
    }

    .leftside {
        width: 48%;
        height: 200px;
        position: relative;
        border-radius: 6px;
        background: #F5F6FA;
        padding: 1rem;
        border-radius: 6px;
        height: calc(100% - 0rem);
    }

    .text {
        //styleName: xl/Semibold;
        color: #08182E;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: -0.01em;
        text-align: left;
        margin-bottom: 1rem;

    }

    .rightside {
        width: 48%;
        height: 200px;
        overflow: scroll;
        background: #F5F6FA;
        padding: 1rem;
        border-radius: 6px;
        height: calc(100% - 0rem);
    }

    .questionsectBox {
        width: 100%;
        border: 1px solid #E5E5E5;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        //display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0.8rem 1rem;
        border-radius: 5px;
        margin-bottom: 0.5rem;
        // background: #FFF7F3;

        &:nth-child(1n) {
            background: #F3F4F6;
        }

        &:nth-child(2n) {

            background: #FFF7F3;
        }

        &:hover {
            transform: scale(1) translateY(-2px);
        }

        svg {
            margin-left: 8px;
        }
    }

    .questionsectionName {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        line-height: 25px;
        text-align: left;
        color: #121212;
        margin-bottom: 1rem;
    }

    .questionsection {
        margin-top: 1rem;
    }

    .listItem {
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.85px;
        text-align: left;
        color: #121212;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        div {
            color:
                #121212;
        }
    }

    .eachOption {
        font-size: 1rem;
        padding: 1.2rem 1.2rem;
        border: 1px solid #e1e1e1;
        border-radius: 7px;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.15s;
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .closesection {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #181818;
        padding: 1rem;
    }

    .close {
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        line-height: 14.63px;
        text-align: center;
        color: #121212;
        width: 97px;
        height: 34px;
        border: 1px solid #E5E5E5;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
    }

    .eachOption {
        font-size: 1rem;
        padding: 0rem 0rem;
        border: 1px solid #e1e1e1;
        border-radius: 7px;
        font-weight: 500;
        cursor: pointer;
        transition: all linear 0.15s;
        margin-bottom: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #E8EBF0;
    }
}

.inputpicker {
    &::placeholder {
        color: #5F6163 !important;
        opacity: 1 !important;
    }
}