.landing {
  display: flex;
  height: calc(100vh - 130px - 7rem);
  background: var(--light-bg);

  & > div {
    width: 50%;
  }

  & > .leftContainer {
    padding: 2rem;
    display: flex;
    align-items: center;

    & > .contentBox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 2rem;
      padding: 0 2rem;

      & > .title {
        font-size: 3rem;
        font-weight: 450;
        font-family: "Montserrat";
        // font-style: italic;
        // line-height: 1.5;

        & > span {
          background: #8d5407;
          color: var(--main-color);
          padding: 5px 10px;
        }
      }

      & > .desc {
        font-size: 1.5rem;
        font-weight: 380;
        line-height: 1.5;
        font-family: "Montserrat";

        & > :nth-child(2) {
          margin-top: 1rem;
        }
      }

      & > .bookBtn {
        display: flex;
        width: max-content;
        background: var(--main-color);
        color: white;
        border-radius: 10px;
        cursor: pointer;
        padding: 0.8rem 2rem;
        font-weight: 500;
        font-size: 1rem;
        transition: all linear 0.3s;

        &:hover {
          background: #8d5407;
          color: var(--main-color);
        }
      }
    }
  }

  & > .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    // background: var(--main-color);
    // border-radius: 10px;
    background: #191f41;

    & > svg {
      height: 70% !important;
    }
  }
}

@media screen and (max-width: 800px) {
  .landing {
    flex-direction: column;
    height: max-content;

    & > div {
      width: 100%;
    }

    & > .leftContainer {
      padding: 2rem 0;

      & > .contentBox {
        text-align: center;
        align-items: center;
        padding: 2rem 1.5rem;

        & > .bookBtn {
          padding: 1rem;
          font-weight: 550;
        }
      }
    }

    & > .imageContainer {
      height: 40vh;
    }
  }
}
